import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormInput,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "shards-react";

import PageTitle from "./../components/common/PageTitle";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { simulations } from "../actions";

const calculateData = async (simulation) => {
  const consumo_gj_total = simulation?.days?.reduce((acc, obj) => {
    return acc + obj.consumo_gj;
  }, 0);

  const excedentes = await simulation?.days?.filter(
    (obj) => obj.consumo_gj > simulation?.cmd
  );

  const consumo_superior = await excedentes?.reduce((acc, obj) => {
    return acc + (obj.consumo_gj - simulation?.cmd);
  }, 0);

  const total_transporte_firme = await simulation?.days?.reduce((acc, obj) => {
    return acc + (simulation?.t_sistrangas + simulation?.ttp) * simulation?.cmd;
  }, 0);

  const total_interrumpible = await excedentes?.reduce((acc, obj) => {
    return (
      acc +
      (simulation?.ti + simulation?.ttpi) * (obj.consumo_gj - simulation?.cmd)
    );
  }, 0);

  const molecula =
    (await (consumo_gj_total - consumo_superior)) *
    simulation?.acquisition_price;

  const penalizacion =
    consumo_superior * (1.15 * simulation?.acquisition_price);

  const subtotal_acquisition =
    molecula + total_transporte_firme + total_interrumpible + penalizacion;

  const molecule_price = subtotal_acquisition / consumo_gj_total || 0;

  const importe_transporte = total_transporte_firme + total_interrumpible;

  return {
    molecule_price,
    subtotal_acquisition,
    importe_transporte,
    consumo_superior,
  };
};

class SimulationCMDT extends Component {
  state = {
    monthly_consumption: 0,
    cmd: 0,
    spread: 0,
    management_cost: 0,
    month: "",
    tariff: "",
    region: "",
    index: "",
    additional_fields: [],
    customer_id: null,
    saving: false,
    days: [],
    open: false,
    file: null,
    visible: false,
    countdown: 0,
    timeUntilDismissed: 5,
    errorMessage: "",
    molecule_price: 0,
    subtotal_acquisition: 0,
    importe_transporte: 0,
    consumo_superior: 0,
    updated: false,
  };

  update_additional_field(e, index, value) {
    let additional_fields = this.state.additional_fields;
    additional_fields[index].amount = value;
    this.setState({ poll_values: additional_fields });
  }

  componentDidMount() {
    let simulationId = this.props.match.params.id;
    this.props.fetchQuoteSimulation(simulationId).then(async () => {
      let simulation = this.props.quoteSimulations.simulation;
      let additional_fields = [];
      simulation.additional_fields.map(
        (field, index) => (additional_fields[index] = field)
      );

      const data = await calculateData(simulation);

      this.setState({
        tariff: simulation.tariff,
        month: simulation.month,
        region: simulation.region,
        cmd: simulation.cmd,
        monthly_consumption: simulation.monthly_consumption,
        spread: simulation.spread,
        management_cost: simulation.management_cost,
        index: simulation.index,
        additional_fields: additional_fields,
        customer_id: simulation.customer_id,
        days: simulation.days,
        ...data,
      });
    });
    this.props.fetchOptions();
  }

  async componentDidUpdate() {
    if (this.state.updated) {
      const simulation = await this.props.quoteSimulations.simulation;
      const data = await calculateData(simulation);
      this.setState({ ...data, updated: false });
    }
  }

  numberFormat = (n) => {
    if (n !== "en-US") {
      return n.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 4,
      });
    }
    return "-";
  };

  fileChangeHandler = (e) => {
    this.setState({
      file: e.target.files[0],
    });
  };

  submitSimulation = (e) => {
    e.preventDefault();
    if (this.state.saving) {
      return;
    }

    this.setState({ saving: true });

    let values = {
      monthly_consumption: this.state.monthly_consumption,
      cmd: this.state.cmd,
      month: this.state.month,
      tariff: this.state.tariff,
      region: this.state.region,
      spread: this.state.spread,
      management_cost: this.state.management_cost,
      index: this.state.index,
      // additional_fields: [{code: "os", amount: 1000, name: "One Shot"}],
      additional_fields: this.state.additional_fields,
    };
    this.props.updateSimulation(this.props.match.params.id, values).then(() => {
      this.setState({ saving: false, updated: true });
    });
  };

  toggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  showAlert = () => {
    this.clearInterval();
    this.setState({ visible: true, countdown: 0, timeUntilDismissed: 5 });
    this.interval = setInterval(this.handleTimeChange, 1000);
  };

  handleTimeChange = () => {
    if (this.state.countdown < this.state.timeUntilDismissed - 1) {
      this.setState({
        ...this.state,
        ...{ countdown: this.state.countdown + 1 },
      });
      return;
    }

    this.setState({ ...this.state, ...{ visible: false } });
    this.clearInterval();
  };

  clearInterval = () => {
    clearInterval(this.interval);
    this.interval = null;
  };

  submitDay = (e) => {
    e.preventDefault();
    if (this.state.saving) {
      return;
    }

    this.setState({ saving: true });

    if (this.state.cmd === 0) {
      console.log("No hay cmd");
      this.setState({
        saving: false,
        errorMessage:
          "Por favor ingrese el valor de CMD antes de ingresar valores diarios",
      });
      this.showAlert();
    }

    this.props
      .updateDailyValues(
        this.props.match.params.id,
        this.state.cmd,
        this.state.file
      )
      .then(() => {
        this.setState({ saving: false, open: false });
        window.location.reload();
      });
  };

  componentWillUnmount() {}

  render() {
    let simulation = null;
    let simulation_options = this.props.quoteSimulations.simulation_options;
    let content = "";
    simulation = this.props.quoteSimulations.simulation;
    let type = simulation.customer_type;
    let customer_name = "";
    let spread_name = "Spread";
    // const additional_fields = this.state.additional_fields;
    const { additional_fields, canon } = this.state;
    const canon_data = this.props.quoteSimulations.canon;
    const sim_id = this.props.match.params.id;
    if (this.state.index === "PEMEX") {
      spread_name = "Descuento";
    }
    const quote_additional_fields =
      this.props.quoteSimulations.simulation.additional_fields;
    const month_formatter = new Intl.DateTimeFormat("es", { month: "long" });

    let month_note = `* El precio de adquisición del mes de ${month_formatter.format(
      new Date()
    )} es estimado`;

    if (Object.entries(simulation).length > 0) {
      customer_name = simulation.customer_name;
      content = (
        <Col lg="8" className="">
          <Card small className="my-4">
            <CardBody className="d-flex flex-column">
              <Row>
                <Col lg={12} className="note">
                  {month_note}
                </Col>
                <Col lg={12}>
                  <div className="table-responsive">
                    <table className="table simulation-results">
                      <thead className="bg-light">
                        <tr>
                          <th>Simulación de Factura</th>
                          <th>Gj</th>
                          <th>Unitario</th>
                          <th>Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Cargo por Servicio</td>
                          <td>1</td>
                          <td>
                            {simulation.region_tariff_price.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </td>
                          <td>
                            {simulation.region_tariff_price.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </td>
                        </tr>
                        {simulation.use_block.map(
                          (block, id) =>
                            block.consumption > 0 && (
                              <tr key={`use_block_${id}`}>
                                <td>
                                  D Simple cargo por uso{" "}
                                  {block.block_pricing.block.name}
                                </td>
                                <td>
                                  {block.consumption.toLocaleString(
                                    "en-US",
                                    {}
                                  )}
                                </td>
                                <td>
                                  {block.block_pricing.price.toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </td>
                                <td>
                                  {block.total.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                            )
                        )}
                        {simulation.cap_block.map(
                          (block, id) =>
                            block.consumption > 0 && (
                              <tr key={`use_block_${id}`}>
                                <td>
                                  D Simple cargo por capacidad{" "}
                                  {block.block_pricing.block.name}
                                </td>
                                <td>
                                  {block.consumption.toLocaleString(
                                    "en-US",
                                    {}
                                  )}
                                </td>
                                <td>
                                  {block.block_pricing.price.toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </td>
                                <td>
                                  {block.total.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                            )
                        )}
                        {simulation.com_block.map(
                          (block, id) =>
                            block.consumption > 0 && (
                              <tr key={`use_block_${id}`}>
                                <td>
                                  Distribución con Comercialización{" "}
                                  {block.block_pricing.block.name}
                                </td>
                                <td>
                                  {block.consumption.toLocaleString(
                                    "en-US",
                                    {}
                                  )}
                                </td>
                                <td>
                                  {block.block_pricing.price.toLocaleString(
                                    "en-US",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </td>
                                <td>
                                  {block.total.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                            )
                        )}
                        <tr>
                          <td>Precio de Adquisición</td>
                          <td>
                            {simulation.monthly_consumption.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </td>
                          <td>
                            {this.state.molecule_price.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td>
                            {this.state.subtotal_acquisition.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Ajuste Operativo</td>
                          <td>
                            {simulation.monthly_consumption.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </td>
                          <td>
                            {simulation.operational_adjustment.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </td>
                          <td>
                            {simulation.operational_adjustment_total.toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </td>
                        </tr>
                        {quote_additional_fields.map((field, index) => (
                          <tr key={`quote_additional_fields_${index}`}>
                            <td>{field.readable_name}</td>
                            <td></td>
                            <td></td>
                            <td>
                              {field.amount.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                        ))}
                        <tr className="top-em">
                          <td>Total factura sin IVA</td>
                          <td></td>
                          <td></td>
                          <td>
                            {(
                              simulation.quote_total -
                              simulation.acquisition_total +
                              this.state.subtotal_acquisition
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                        <tr className="">
                          <td>Total factura con IVA</td>
                          <td></td>
                          <td></td>
                          <td>
                            {(
                              simulation.quote_total_tax -
                              simulation.acquisition_total +
                              this.state.subtotal_acquisition
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card small className="my-4">
            <CardBody className="d-flex flex-column">
              <Row>
                <Col sm="6">
                  <h6>Valores Diarios</h6>
                </Col>
                <Col sm="6">
                  <Button
                    href="https://cdn.blackpixel.mx/naturgy/consumoDiario.xlsx"
                    download
                    className="mr-4"
                  >
                    Descargar formato
                  </Button>
                  <Button onClick={this.toggle}>Agregar valores</Button>
                </Col>
              </Row>
              <Modal open={this.state.open} toggle={this.toggle} centered>
                <ModalHeader>Agregar valor diario</ModalHeader>
                <ModalBody>
                  <Form onSubmit={this.submitDay}>
                    <Row>
                      <Col lg="12" className="form-group">
                        <label>Archivo</label>
                        <FormInput
                          type="file"
                          onChange={this.fileChangeHandler}
                          required
                        />
                      </Col>
                      <Col lg="12" className="my-4">
                        <Button
                          type="submit"
                          className="col-lg-12"
                          disabled={this.state.saving}
                        >
                          Agregar Archivo
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </ModalBody>
              </Modal>

              <Col lg={12}>
                <div className="table-responsive">
                  <table className="table simulation-results">
                    <thead className="bg-light">
                      <tr>
                        <th>Día</th>
                        <th>CMD</th>
                        <th>Consumo GJ</th>
                        <th>Diferencia</th>
                      </tr>
                    </thead>
                    <tbody>
                      {simulation.days.map((data, id) => (
                        <tr key={id}>
                          <td>{data["fecha"]}</td>
                          <td>{data["cmd"]}</td>
                          <td>{data["consumo_gj"]}</td>
                          <td>{data["cmd"] - data["consumo_gj"]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Col>
            </CardBody>
          </Card>

          <Card small className="my-4">
            <CardBody>
              <h6>Comparativo vs Combustibles Alternos</h6>
              <div className="table-responsive">
                <table className="table simulation-results">
                  <thead>
                    <tr>
                      <th>Combustible</th>
                      <th>Subtotal</th>
                      <th>Competitividad</th>
                      <th>Ahorro</th>
                    </tr>
                  </thead>
                  <tbody>
                    {simulation.alternate_fuels.map((fuel, id) => (
                      <tr key={`fuel_${id}`}>
                        <td>{fuel.name}</td>
                        <td>{this.numberFormat(fuel.value)}</td>
                        <td>{this.numberFormat(fuel.competitivity)}%</td>
                        <td>{this.numberFormat(fuel.savings)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      );
    }

    let container = (
      <>
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}

          <Row noGutters className="page-header py-4">
            <PageTitle
              title={`Simulación CMDT para ${customer_name}`}
              className="text-sm-left mb-3 col-sm-12"
            />
          </Row>
          <Row>
            <Col className="my-2">
              <Link to={`/clientes/${this.state.customer_id}`}>
                <Button className="my-4 my-md-2 col-md-12 col-lg-2">
                  Regresar
                </Button>
              </Link>
              <Link
                to={`/pdf/cmdt/${sim_id}/download/`}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <Button className="float-right col-md-12 col-lg-2">
                  Descargar
                </Button>
              </Link>
            </Col>
          </Row>

          <Row>
            {/* New Draft */}
            {content}

            <Col lg="4" className="mb-8">
              <Row>
                <Col md="12">
                  <Card small className="my-4">
                    {/* Card Header */}

                    <CardBody className="d-flex flex-column">
                      <Row>
                        <Col>
                          <Form onSubmit={this.submitSimulation}>
                            <Button
                              type="submit"
                              className="my-2 col-lg-12"
                              disabled={this.state.saving}
                            >
                              Actualizar
                            </Button>

                            <table className="table table-sm simulation-data">
                              <tbody>
                                <tr>
                                  <td>
                                    <span className="ng-blue">Mes</span>
                                  </td>
                                  <td>
                                    <FormSelect
                                      value={this.state.month}
                                      onChange={(e) =>
                                        this.setState({ month: e.target.value })
                                      }
                                    >
                                      <option value="">---</option>
                                      {simulation_options.months.map(
                                        (tariff, id) => (
                                          <option key={id} value={tariff[0]}>
                                            {tariff[1]}
                                          </option>
                                        )
                                      )}
                                    </FormSelect>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Zona</td>
                                  <td>
                                    <FormSelect
                                      value={this.state.region}
                                      onChange={(e) =>
                                        this.setState({
                                          region: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="">---</option>
                                      {simulation_options.regions.map(
                                        (tariff, id) => (
                                          <option key={id} value={tariff[0]}>
                                            {tariff[1]}
                                          </option>
                                        )
                                      )}
                                    </FormSelect>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Tipo de tarifa</td>
                                  <td>
                                    <FormSelect
                                      value={this.state.tariff}
                                      onChange={(e) =>
                                        this.setState({
                                          tariff: e.target.value,
                                        })
                                      }
                                    >
                                      <option value="">---</option>
                                      {simulation_options.tariffs.map(
                                        (tariff, id) => (
                                          <option key={id} value={tariff[0]}>
                                            {tariff[1]}
                                          </option>
                                        )
                                      )}
                                    </FormSelect>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Consumo mensual</td>
                                  <td>
                                    <FormInput
                                      id="contract-id"
                                      type="number"
                                      value={this.state.monthly_consumption}
                                      onChange={(e) =>
                                        this.setState({
                                          monthly_consumption: e.target.value,
                                        })
                                      }
                                      required
                                    />
                                  </td>
                                </tr>
                                {type === "g" && (
                                  <tr>
                                    <td>CMD</td>
                                    <td>
                                      <FormInput
                                        id="contract-id"
                                        type="number"
                                        value={this.state.cmd}
                                        onChange={(e) =>
                                          this.setState({ cmd: e.target.value })
                                        }
                                        required
                                      />
                                    </td>
                                  </tr>
                                )}
                                {type === "g" && (
                                  <tr>
                                    <td>Capacidad Mensual</td>
                                    <td>
                                      {simulation.monthly_capacity.toLocaleString(
                                        "en-US",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </td>
                                  </tr>
                                )}

                                {additional_fields.map((field, index) => (
                                  <tr key={`additional_fields_${index}`}>
                                    <td>{field.readable_name}</td>
                                    <td>
                                      <FormInput
                                        id="contract-id"
                                        type="number"
                                        value={
                                          this.state.additional_fields[index][
                                            "amount"
                                          ]
                                        }
                                        onChange={(e) =>
                                          this.update_additional_field(
                                            e,
                                            index,
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Form>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                {type === "g" && (
                  <Col lg="12" className="">
                    <Card small className="">
                      <CardBody className="d-flex flex-column">
                        <Row>
                          <Col>
                            <Form onSubmit={this.submitSimulation}>
                              <table className="table table-sm simulation-data">
                                <tbody>
                                  <tr>
                                    <td>Índice</td>
                                    <td>
                                      <FormSelect
                                        value={this.state.index}
                                        onChange={(e) =>
                                          this.setState({
                                            index: e.target.value,
                                          })
                                        }
                                      >
                                        <option value="">---</option>
                                        {simulation_options.indexes.map(
                                          (tariff, id) => (
                                            <option key={id} value={tariff[0]}>
                                              {tariff[1]}
                                            </option>
                                          )
                                        )}
                                      </FormSelect>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>{spread_name}</td>
                                    <td>
                                      <FormInput
                                        id="contract-id"
                                        type="number"
                                        value={this.state.spread}
                                        onChange={(e) =>
                                          this.setState({
                                            spread: e.target.value,
                                          })
                                        }
                                        required
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Molécula</td>
                                    <td>
                                      {this.numberFormat(simulation.molecule)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>T SISTRANGAS</td>
                                    <td>
                                      {this.numberFormat(
                                        simulation.t_sistrangas
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>T. Tans. Priv.</td>
                                    <td>{this.numberFormat(simulation.ttp)}</td>
                                  </tr>
                                  <tr>
                                    <td>Gas Fuel</td>
                                    <td>
                                      {this.numberFormat(simulation.gas_fuel)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>C. gestión [MXN]</td>
                                    <td>
                                      <FormInput
                                        id="contract-id"
                                        type="number"
                                        value={this.state.management_cost}
                                        onChange={(e) =>
                                          this.setState({
                                            management_cost: e.target.value,
                                          })
                                        }
                                        required
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Precio de adquisición</td>
                                    <td>
                                      {this.numberFormat(
                                        this.state.molecule_price
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Subtotal Precio Adquisición</td>
                                    <td>
                                      {this.numberFormat(
                                        this.state.subtotal_acquisition
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Importe por transporte (mxn)</td>
                                    <td>
                                      {this.numberFormat(
                                        this.state.importe_transporte
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Consumo superior CMD (gj)</td>
                                    <td>
                                      {this.numberFormat(
                                        this.state.consumo_superior
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Form>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
    return container;
  }
}

/**
 * Función que mapea el estado a las props del componente
 * @param {Object} state - Estado actual de la aplicación
 * @returns {Object} - Objeto que contiene las props mapeadas al estado
 */
const mapStateToProps = (state) => {
  //console.log("Estado", state);
  return {
    quoteSimulations: state.simulations,
  };
};
/**
 * Función que mapea las acciones a las props del componente
 * @param {Function} dispatch - Función de redux para despachar acciones
 * @returns {Object} - Objeto que contiene las props mapeadas a las acciones
 */
const mapDispatchToProps = (dispatch) => {
  return {
    fetchQuoteSimulation: (simulationId) => {
      return dispatch(simulations.fetchCMDTSimulation(simulationId));
    },
    fetchOptions: () => {
      dispatch(simulations.fetchOptions());
    },
    updateSimulation: (simulation_id, values) => {
      return dispatch(simulations.updateSimulation(simulation_id, values));
    },
    updateDailyValues: (simulation_id, cmd, values) => {
      return dispatch(
        simulations.updateDailyValues(simulation_id, cmd, values)
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SimulationCMDT);
